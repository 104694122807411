import { FC, useEffect } from "react";
import { useCommonContainerStyles } from "@/styles";
import { useHeaderContext } from "@/providers";
import { Box, Button, format, formatTime, Typography } from "@toolkit/ui";
import { SingleInfoWithIconDetailCard } from "../../components/DetailCard/SingleInfoWithIconDetailCard";
import { useTranslation } from "@/i18n/i18n.config";
import { useAppointmentPaymentSummaryStyles } from "./AppointmentPaymentSummaryStyles";
import { PaymentSummary } from "@/domains/payment";
import { AmountSourceType, AppointmentType, PaymentStatus } from "@health/queries/types";
import { PayNowDialog } from "@/domains/payment/pay-now-dialog/pay-now-dialog";
import { useAppointmentPaymentSummary } from "./useAppointmentPaymentSummary";
import { appointmentTypeOptionsMap } from "@health/enum-options";
import { AppointmentTypeIconMap } from "../../utils";
import { DetailCard } from "../../components/DetailCard/DetailCard";
import { AppointmentDoctorCard } from "../../components/AppointmentDoctorCard/AppointmentDoctorCard";
import { getDoctorInfo } from "../../utils/index";
import { routes as notificationsRoutes } from "@/domains/notifications/routes";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { routesPaths } from "@/routing/routesPaths";

export const AppointmentPaymentSummary: FC = () => {
  const { t } = useTranslation("consumer");
  const { classes: commonClasses } = useCommonContainerStyles();
  const { classes, cx } = useAppointmentPaymentSummaryStyles();

  const { appointmentId, isLoading, appointment } = useAppointmentPaymentSummary();
  const { setHeaderTitle } = useHeaderContext();
  const navigate = useNavigate();

  const AppointmentTypeIcon = AppointmentTypeIconMap[appointment?.type || AppointmentType["AtHome"]];
  const handlePay = () => {
    PayNowDialog.open();
    localStorage.setItem("payment-call-back-source-page", notificationsRoutes.root.absRoute);
  };
  const handleRescheduleClick = () => {
    navigate(routes.reschedule.getRouteWithParams({ appointmentId, doctorId: appointment?.doctor?.id || "" }));
  };

  useEffect(() => {
    if (appointment?.paymentStatus === PaymentStatus.PaymentUnavailable) navigate(routesPaths.home);
    setHeaderTitle(t("Appointment payment summary"));
  }, []);
  return (
    <Box className={commonClasses.pageContainer}>
      <Box className={commonClasses.pageContent}>
        {appointment?.paymentStatus !== PaymentStatus.PaymentUnavailable && (
          <PayNowDialog amountSource={AmountSourceType.Appointment} amountSourceId={appointmentId || ""} />
        )}
        <DetailCard title={t("Appointment Date")} content={format(appointment?.confirmedStartTime)} />

        <DetailCard
          title={t("Appointment Time")}
          content={
            <Typography>
              {formatTime(appointment?.confirmedStartTime)} - {formatTime(appointment?.confirmedEndTime)}
            </Typography>
          }
        />

        <SingleInfoWithIconDetailCard
          className={classes.infoCard}
          title={t("Appointment type")}
          content={appointment?.type ? appointmentTypeOptionsMap[appointment.type]?.label : ""}
          startIcon={<Box className={classes.iconContainer}>{<AppointmentTypeIcon />}</Box>}
        />
        {appointment?.doctor && <AppointmentDoctorCard {...getDoctorInfo(appointment.doctor)} />}
        <PaymentSummary
          priceBeforeVat={appointment?.paymentInfo?.totalAmount || undefined}
          priceAfterVat={appointment?.paymentInfo?.priceAmountAfterVat || undefined}
        />
      </Box>
      <Box className={cx(commonClasses.footer, classes.footer)}>
        <Button disabled={isLoading} onClick={handlePay} className={cx(commonClasses.button, classes.button)}>
          {t("Pay Now")}
        </Button>
        <Button variant='outlined' onClick={handleRescheduleClick} className={cx(commonClasses.button, classes.button)}>
          {t("Reschedule")}
        </Button>
      </Box>
    </Box>
  );
};
