import { conferenceCallDropReasonFailure } from "@meetora/schema/calls/conference/ConferenceCallDropReason";
import { useTranslation } from "@toolkit/i18n";
import { useCallback, useEffect, useMemo } from "react";
import { CallInstance, ConnectionState } from "./Meetora";
import { useDebounce, useNetwork } from "@toolkit/core";
import { useQueryRetry } from "./useQueryRetry";

const MAX_ATTEMPTS = 3;

export const useConnectionState = (call: CallInstance) => {
  const { dropCall } = call;
  const { callState } = call.state;
  const { t } = useTranslation();
  const isOnline = useNetwork();
  const { connectionState, isConnecting, joinSession: handleJoin } = call.zoomInstance ?? {};
  const isConnected = connectionState === ConnectionState.Connected;
  const isEnabled = isOnline && !isConnected && callState === "in_call";
  const handleDropCall = useCallback(async () => {
    await dropCall(conferenceCallDropReasonFailure());
  }, [dropCall]);

  const { isRetrying, attemptsRef } = useQueryRetry({
    query: handleJoin,
    attempts: MAX_ATTEMPTS,
    enabled: !isConnecting && isEnabled,
    // trigger a retry after 5 seconds
    timeout: 5000,
  });

  const isLoading = useDebounce(isRetrying || isConnecting, 200);
  useEffect(() => {
    if (isConnected) {
      attemptsRef.current = 0;
      return;
    }

    if (isLoading || attemptsRef.current < MAX_ATTEMPTS) {
      return;
    }

    const intervalId = setInterval(handleDropCall, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [attemptsRef, connectionState, handleDropCall, isConnected, isLoading]);

  const attempts = attemptsRef.current;
  const title = useMemo(() => {
    if (!isEnabled) {
      return null;
    }

    if (attempts === 0) {
      return t("Connecting ...");
    }

    if (attempts === MAX_ATTEMPTS && !isLoading) {
      return t("Connection failed. Disconnecting ...");
    }

    return t("Session disconnected. Trying to reconnect...");
  }, [attempts, isLoading, isEnabled, t]);

  return { isLoading, isEnabled, isConnected, title, handleDropCall };
};
