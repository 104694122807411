import React from "react";
import { Box, TextField, Typography, useTheme } from "../base/mui";
import { CustomIcon, paths } from "../components";
import * as AllIcons from "./icons";

interface IconsExplorerProps {
  icons?: Record<string, React.ComponentType>;
}

const IconsExplorer: React.FC<IconsExplorerProps> = ({ icons = {} }) => {
  const theme = useTheme();

  const allIconNames = Object.keys(AllIcons);
  const pathIconNames = Object.keys(paths);
  const additionalIconNames = Object.keys(icons);

  const allIcons = [
    ...allIconNames.map(name => ({ name, source: "all" })),
    ...pathIconNames.map(name => ({ name, source: "path" })),
    ...additionalIconNames.map(name => ({ name, source: "additional" })),
  ];

  const [value, setValue] = React.useState("");

  const filteredIcons = value ? allIcons.filter(icon => icon.name.toLowerCase().includes(value.toLowerCase())) : allIcons;

  const handleChangeIcons = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const getBackgroundColor = (source: string) => {
    switch (source) {
      case "all":
        return theme.palette.gray[300];
      case "path":
        return theme.palette.secondary[300];
      case "additional":
        return theme.palette.yellow[300];
      default:
        return theme.palette.background.default;
    }
  };

  const getIconComponent = (name: string, source: string) => {
    switch (source) {
      case "all":
        return (AllIcons as any)[name];
      case "path":
        return () => <CustomIcon icon={name as any} width={"calc(100% -30px)!important"} height={"calc(100% -40px)!important"} />;
      case "additional":
        return icons[name] || (() => null);
      default:
        return () => null;
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box>
        <TextField placeholder='Search Icons' value={value} onChange={handleChangeIcons} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          rowGap: 2,
          columnGap: 2,
          justifyContent: "space-between",
        }}
      >
        {filteredIcons.map((icon, index) => {
          const IconComponent = getIconComponent(icon.name, icon.source);
          return (
            <Box
              key={`${icon.name}-${icon.source}-${index}`} // Unique key for each icon
              sx={{
                "& svg": {
                  width: "50px",
                  height: "50px",
                  backgroundColor: getBackgroundColor(icon.source),
                },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "50px",
                height: "50px",
              }}
            >
              <IconComponent />
              <Typography fontSize={8} mt={1}>
                {icon.name}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default IconsExplorer;
