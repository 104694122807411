import { FC } from "react";
import { useAuth } from "react-oidc-context";
import { MeetoraCall } from "@health/meetora";

type VisitCallProps = {
  meetingLink: string;
};

export const VisitCall: FC<VisitCallProps> = ({ meetingLink }) => {
  // todo: apply refresh token logic
  const { user } = useAuth();
  const token = user?.access_token;
  return !token ? <div>Unauthorized</div> : <MeetoraCall invitationCode={meetingLink} />;
};
