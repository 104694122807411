import { CallContent } from "./CallContent";
import { CallError } from "./CallError";
import { useCall } from "./Meetora";
import { useMeetingInfo } from "./useMeetingInfo";

type CallProps = {
  invitationCode: string;
  renderDoctorConsole?: () => JSX.Element;
  onClose?: () => void;
};

export const CallView = ({ invitationCode, renderDoctorConsole, onClose }: CallProps) => {
  const { meetingInfo, error, loading, handleRetry } = useMeetingInfo({ invitationCode });
  const call = useCall({ peer: meetingInfo?.peer });
  if (error || loading) {
    return <CallError isLoading={loading} error={error} onClick={handleRetry} />;
  }

  return <CallContent call={call} meetingInfo={meetingInfo} onClose={onClose} renderDoctorConsole={renderDoctorConsole} />;
};
