import { memo } from "react";
import { useMeetingDuration } from "./useMeetingDuration";

type MeetingDuration = {
  startedAt: number;
};

export const MeetingDuration = memo(({ startedAt }: MeetingDuration) => {
  const { duration } = useMeetingDuration({ startedAt });

  return <>{duration}</>;
});
