import { TError } from "@meetora/schema/TError";
import { useTranslation } from "@toolkit/i18n";

type MeetoraMessageErrorProps = {
  error?: TError;
};

export const useGetMessageError = ({ error }: MeetoraMessageErrorProps) => {
  const { t } = useTranslation();

  if (!error) {
    return null;
  }

  switch (error._name) {
    case "errorInternalAppErrorOther": {
      if (["ErrBadMsgIdTooLow", "ErrBadMsgIdTooHigh"].includes(error.reason)) {
        return t("Unable to connect to the server, please check your date-time settings and try again.");
      }

      break;
    }
    case "errorInternalAppErrorAuthKeyGenerationFailed": {
      return t("Unable to connect to the server, make sure your connection is not blocked by a firewall or proxy.");
    }
    case "errorInternalServerError": {
      return t("Internal server error, please try again later.");
    }
    case "errorInternalAppErrorLostConnection": {
      return t("The connection to the server has been lost. Please check your internet connection and try again.");
    }
    case "errorInternalAppErrorRequestTimedOut": {
      return t("The request is taking too long to process. Please try again.");
    }
    case "errorNotFoundConversation": {
      return t("Conversation not found.");
    }
    case "errorNotFound": {
      return t("Invitation not found.");
    }
    case "errorBadRequestTokenExpired": {
      return t("Invitation expired.");
    }
    case "errorUnauthorizedAccount": {
      return t("Unauthorized Account.");
    }
    case "errorInternalAppErrorProtocolError": {
      return t("Error code: {{ code }}", { code: error.code });
    }
  }

  return t('Unable to connect due to the following error "{{code}}". Please retry.', { code: error._name! });
};
