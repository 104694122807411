import { useMemo } from "react";
import useSelectPeerFromInvitationLink from "@meetora/react/sehacity/Chat/useSelectPeerFromInvitationLink";
import { inputConferencePeerAnonymousChat } from "@meetora/schema/calls/conference/InputConferencePeer";

export const useMeetingInfo = ({ invitationCode }: { invitationCode?: string }) => {
  const code = useMemo(() => invitationCode?.replace(/(^")|("$)/g, ""), []);
  const invitationLink = useMemo(() => {
    try {
      if (code && code.startsWith("https://")) {
        return new URL(code).origin;
      }
    } catch {
      // ignore
    }
    return "";
  }, [code]);

  const { data: invitationLinkPeer, loading, error, refetch: handleRetry } = useSelectPeerFromInvitationLink(code);

  const meetingInfo = useMemo(() => {
    if (invitationLinkPeer && invitationLinkPeer._name === "conversation.invitationInfoAnonymousChat") {
      return {
        peer: inputConferencePeerAnonymousChat({ anonChatId: invitationLinkPeer.anonChat.id }),
        invitationLink,
      };
    }

    return undefined;
  }, [invitationLink, invitationLinkPeer, code]);

  return { meetingInfo, loading, error, handleRetry };
};
