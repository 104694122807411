import { getEnvVariable } from "@/env";
import { AppointmentsIcon, AtHomeIcon } from "@toolkit/ui";
import i18n from "../../i18n/i18n.config";
import { routesPaths } from "../../routing/routesPaths";
import {
  ArabicIcon,
  BookAnAppointmentIcon,
  CallADoctorNowIcon,
  ConnectToCareTeamIcon,
  DependentsIcon,
  InfoIcon,
  InsuranceIcon,
  JoinSessionIcon,
  KnowledgeHubIcon,
  MedicineIcon,
  MenuSettingIcon,
  PaymentCardIcon,
  PreApprovalIcon,
  ProductIcon,
  WalletIcon,
  WishListHeartIcon,
} from "../Icons";
import { PaperIcon } from "../Icons/menuIcons/PaperIcon";
import { sideMenuDrawerOpenStateVar } from "../SideMenuDrawer/sideMenuDrawerOpenStateVar";
import { routes as appointmentsRoutes } from "@/domains/appointment/routes";

export interface MenuItem {
  text: string;
  link?: string;
  icon: React.ComponentType;
  onclick?: () => void;
  disabled?: boolean;
  hideForGuest?: boolean;
}

function getDrawerClickHandler(fn: () => void) {
  return function () {
    fn();
    sideMenuDrawerOpenStateVar(false);
  };
}

export function getMenuItems(walletBalance: string, _i18n: typeof i18n, isAuthenticated: boolean): MenuItem[][] {
  const devMenuItems: MenuItem[] =
    getEnvVariable("SADA_API_ENV") === "dev"
      ? [{ text: _i18n.t("Playground", { ns: "consumer" }), link: routesPaths.playGround, icon: InfoIcon }]
      : [];

  return [
    [{ text: _i18n.t("Home", { ns: "consumer" }), link: routesPaths.home, icon: AtHomeIcon }, ...devMenuItems],

    [
      { text: _i18n.t("Call a Doctor Now", { ns: "consumer" }), link: routesPaths.virtualVisit, icon: CallADoctorNowIcon },
      { text: _i18n.t("Connect to a Care Team", { ns: "consumer" }), link: routesPaths.careTeam, icon: ConnectToCareTeamIcon },
      { text: _i18n.t("Book an Appointment", { ns: "consumer" }), link: routesPaths.appointments, icon: BookAnAppointmentIcon },
      { text: _i18n.t("Order Medications", { ns: "consumer" }), link: "", icon: MedicineIcon, disabled: true },
      { text: _i18n.t("Join a Session", { ns: "consumer" }), link: "", icon: JoinSessionIcon, disabled: true },
      { text: _i18n.t("Knowledge Hub", { ns: "consumer" }), link: "", icon: KnowledgeHubIcon, disabled: true },
    ],

    [
      {
        text: _i18n.t("My Wallet ({{walletBalance}})", { ns: "consumer", walletBalance }),
        link: routesPaths.wallet,
        icon: WalletIcon,
        hideForGuest: true,
      },
      { text: _i18n.t("My Orders", { ns: "consumer" }), link: "", icon: ProductIcon, disabled: true, hideForGuest: true },
      {
        text: _i18n.t("Appointments", { ns: "consumer" }),
        link: appointmentsRoutes?.myAppointments.absRoute,
        icon: AppointmentsIcon,
        hideForGuest: true,
      },
      { text: _i18n.t("Dependents", { ns: "consumer" }), link: "", icon: DependentsIcon, disabled: true, hideForGuest: true },
      { text: _i18n.t("Insurance Cards", { ns: "consumer" }), link: "", icon: InsuranceIcon, disabled: true, hideForGuest: true },
      { text: _i18n.t("My Claims", { ns: "consumer" }), link: "", icon: PaperIcon, disabled: true, hideForGuest: true },
      { text: _i18n.t("My Pre Approvals", { ns: "consumer" }), link: "", icon: PreApprovalIcon, disabled: true, hideForGuest: true },
      { text: _i18n.t("Member Bank Details", { ns: "consumer" }), link: "", icon: PaymentCardIcon, disabled: true, hideForGuest: true },
      { text: _i18n.t("My Wish List", { ns: "consumer" }), link: "", icon: WishListHeartIcon, disabled: true, hideForGuest: true },
      {
        text: _i18n.t("Change Language", { ns: "consumer" }),
        onclick: getDrawerClickHandler(() => {
          if (_i18n.language.startsWith("en")) {
            _i18n.changeLanguage("ar");
          } else {
            _i18n.changeLanguage("en");
          }
        }),
        icon: ArabicIcon,
        link: undefined,
      },
      {
        text: _i18n.t("Account Settings", { ns: "consumer" }),
        link: routesPaths.accountSettings,
        icon: MenuSettingIcon,
        hideForGuest: true,
      },
    ].filter(item => !item.hideForGuest || isAuthenticated),
  ] as const;
}
