import { JoinScreen } from "./JoinScreen";
import { MeetingScreen } from "./MeetingScreen";
import { RatingScreen } from "./RatingScreen";
import { TimeoutScreen } from "./TimeoutScreen";
import { WaitingScreen } from "./WaitingScreen";
import { MeetingInfo, CallState, CallInstance } from "./Meetora";

type CallContentProps = {
  call: CallInstance;
  meetingInfo?: MeetingInfo;
  onClose?: () => void;
  renderDoctorConsole?: () => JSX.Element;
};

export const CallContent = ({ renderDoctorConsole, meetingInfo, call, onClose }: CallContentProps) => {
  const { callState } = call.state;

  if (callState === CallState.NoCall) {
    return <WaitingScreen call={call} onClose={onClose} />;
  }

  if (callState === CallState.Started) {
    return <JoinScreen call={call} onClose={onClose} />;
  }

  if (callState === CallState.Timeout) {
    return <TimeoutScreen call={call} onClose={onClose} />;
  }

  if (callState === CallState.Dropped || callState === CallState.Ended) {
    return <RatingScreen call={call} onClose={onClose} />;
  }

  return <MeetingScreen call={call} renderDoctorConsole={renderDoctorConsole} invitationLink={meetingInfo?.invitationLink} />;
};
