import { CallView } from "./CallView";
import { CallProvider } from "./Meetora";

type CallProps = {
  invitationCode: string;
  renderDoctorConsole?: () => JSX.Element;
  onClose?: () => void;
};

export const Call = (props: CallProps) => {
  return (
    <CallProvider>
      <CallView {...props} />
    </CallProvider>
  );
};
