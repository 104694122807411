import { useCallback, useState } from "react";
import { CallInstance } from "./Meetora";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";

export const useCallHook = ({
  call,
  invitationLink,
  isDoctorConsolEnabled,
}: {
  call: CallInstance;
  invitationLink?: string;
  isDoctorConsolEnabled?: boolean;
}) => {
  const { t } = useTranslation();
  const { succeeded, failed } = useAddToast();

  const [isJoining, setJoining] = useState(false);
  const onToggleVideo = useCallback(async () => {
    const result = await call.muteVideo?.(m => !m);
    if (!result || result.success) {
      return;
    }

    switch (result.type) {
      case "CAN_NOT_DETECT_CAMERA": {
        failed(t("We cannot detect your camera. Please check the connection and try again."));
        break;
      }
      case "NotFoundError":
      case "CAN_NOT_FIND_CAMERA": {
        failed(t("The camera cannot be found. Please check your connections and try again."));
        break;
      }
      case "PermissionDeniedError":
      case "NotAllowedError":
      case "VIDEO_USER_FORBIDDEN_CAPTURE": {
        failed(t("Access to your camera has been denied. Please update the permissions in your browser settings and try again."));
        break;
      }

      case "NotReadableError":
      case "VIDEO_CAMERA_IS_TAKEN": {
        failed(t("The camera is currently in use. Please close any applications that may be using it and try again."));
        break;
      }

      default:
        failed(t("Starting camera failed. Please try again."));
        break;
    }
  }, [call, failed, t]);

  const hasInvitationLink = isDoctorConsolEnabled && invitationLink && call.callInvitationToken;
  const handleShareInvitation = useCallback(async () => {
    if (!invitationLink || !call.callInvitationToken) {
      return;
    }
    try {
      await navigator.clipboard.writeText(`${invitationLink}/1/calls/${call.callInvitationToken}`);
      succeeded(t("Invitation link was copied to clipboard."));
    } catch {
      failed(t("Failed to copy link, Please try again."));
    }
  }, [invitationLink, call.callInvitationToken, t, succeeded, failed]);

  const onToggleAudio = useCallback(async () => {
    const result = await call.muteAudio?.(m => !m);
    if (!result || result.success) {
      return;
    }

    switch (result.type) {
      case "NotFoundError": {
        failed(t("The microphone cannot be found. Please check your connections and try again."));
        break;
      }
      case "PermissionDeniedError":
      case "NotAllowedError":
      case "INSUFFICIENT_PRIVILEGES": {
        failed(t("Access to your microphone has been denied. Please update the permissions in your browser settings and try again."));
        break;
      }
      case "NotReadableError": {
        failed(t("The microphone is currently in use. Please close any applications that may be using it and try again."));
        break;
      }

      default:
        failed(t("Starting microphone failed. Please try again."));
        break;
    }
  }, [call, failed, t]);

  const handleJoin = useCallback(async () => {
    try {
      setJoining(true);
      const result = await call.joinCall();
      switch (result?._name) {
        case "void":
          break;
        case "errorConflict":
        case "errorBadRequestAlreadyInCall":
          failed(t("Sorry. It seems you are already in this call. Please make sure you are only connected through one device at a time."));
          break;
        default:
          failed(t("Failed to join this call, Please try again."));
      }
    } finally {
      setJoining(false);
    }
  }, [call, failed, t]);

  const onToggleShare = useCallback(() => call.muteScreen?.(m => !m), [call]);
  const onToggleSpeaker = useCallback(() => call.muteSpeaker?.(m => !m), [call]);
  const onDropCall = useCallback(() => call.dropCall?.(), [call]);
  const isTakePhotoAllowed = isDoctorConsolEnabled && call.participants.filter(p => !p.isCurrentUser && !p.state.videoMuted).length > 0;

  return {
    isJoining,
    isTakePhotoAllowed,
    isInvitationEnabled: !!isDoctorConsolEnabled && !!call.callInvitationToken,
    hasInvitationLink,
    onToggleVideo,
    onToggleAudio,
    onToggleShare,
    onToggleSpeaker,
    onDropCall,
    handleShareInvitation,
    handleJoin,
  };
};
