import moment from "moment";
import { useEffect, useMemo, useState } from "react";

export const useMeetingDuration = ({ startedAt }: { startedAt: number }) => {
  const [value, setValue] = useState<number>(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const elapsedTime = Date.now() / 1000 - startedAt;
      setValue(elapsedTime >= 0 ? elapsedTime : 0);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [startedAt]);

  const duration = useMemo(() => moment(value * 1000).format(value >= 3600 ? "HH:mm:ss" : "mm:ss"), [value]);

  return { duration };
};
